<template>
  <div>
    {{ /*enter new trade modal*/ }}
    <b-modal
      id="tradeModal"
      scrollable
      v-model="enterNewTradeModal"
      :title="$t('equity_bond.titles.enter_new_trade')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('equity_bond.modals.create_trade_modal_ok')"
      :cancel-title="$t('equity_bond.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
      body-class="compact_form_modal"
      footer-class ="compact_modal_footer"
    >
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <!-- /*******************************Order Details***************************************************************** */ -->
              <b-row class="mt-1">
                <b-card
                  :title="$t('equity_bond.titles.order_details')"
                  class="compact_form_card"
                >
                  <b-row>
                      <!-- order date-->
                      <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.order_date')"
                          class="compact_form_label"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.order_date')"
                            rules="required"
                          >
                            <flat-pickr
                              :placeholder="
                                $t('equity_bond.trade_form.order_date')
                              "
                              v-model="tradeForm.orderDate"
                              onClose="testClose"
                              class="form-control compact_form_flatpickr"
                              :config="orderDateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="checkOrdersDate('Order Date', true)"
                            />

                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order time-->
                      <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.order_time')"
                          class="compact_form_label"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.order_time')"
                            rules="required|min:8"
                          >
                            <cleave
                              id="orderTime"
                              v-model="tradeForm.orderTime"
                              class="form-control compact_form_cleave"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('equity_bond.trade_form.order_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="checkTime('Order Time')"
                            />
                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration date -->
                      <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.order_duration_date')
                          "
                          class="compact_form_label"
                          v-if="orderDurationInputsVisible"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('equity_bond.trade_form.order_duration_date')
                            "
                            :rules="
                              tradeForm.timeInForce == 'GTD' ? 'required' : ''
                            "
                          >
                            <flat-pickr
                              :placeholder="
                                $t('equity_bond.trade_form.order_duration_date')
                              "
                              v-model="tradeForm.orderDurationDate"
                              onClose="testClose"
                              class="form-control compact_form_flatpickr"
                              :config="orderDurationDateConfig"
                              :state="errors.length > 0 ? false : null"
                              :disabled="orderDurationDataDisabled"
                              @on-change="
                                checkOrdersDate('Order Duration Date', true)
                              "
                            />
                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration time -->
                      <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.order_duration_time')
                          "
                          class="compact_form_label"
                          v-if="orderDurationInputsVisible"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('equity_bond.trade_form.order_duration_time')
                            "
                            :rules="
                              tradeForm.timeInForce == 'GTD' ? 'required' : ''
                            "
                          >
                            <cleave
                              id="orderDurationTime"
                              v-model="tradeForm.orderDurationTime"
                              class="form-control compact_form_cleave"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('equity_bond.trade_form.order_duration_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="
                                checkOrdersTime('Order Duration Time')
                              "
                              :disabled="orderDurationTimeDisabled"
                            />

                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    <!-- entry type -->
                    <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.entry_type')"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.entry_type')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.entryType"
                            :options="optEntryTypes"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_entry_type')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            class="compact_form_vue-select"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- buy/sell -->
                    <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.buy_sell')"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.buy_sell')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.buySell"
                            :options="optbuySells"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_buy_sell')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            class="compact_form_vue-select"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- order type-->
                    <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.order_type')"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.order_type')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.orderType"
                            :options="optOrderTypes"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_order_type')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            class="compact_form_vue-select"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- time in force-->
                    <b-col cols="6" xl="1" lg="1" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.time_in_force')"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.time_in_force')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.timeInForce"
                            :options="optTimeInForces"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_time_in_force')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            @option:selected="changeTimeInForceType"
                            class="compact_form_vue-select"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- cpty order date-->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.cpty_order_date')"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cpty_order_date')"
                          rules="required"
                        >
                          <flat-pickr
                            :placeholder="
                              $t('equity_bond.trade_form.cpty_order_date')
                            "
                            v-model="tradeForm.cptyOrderDate"
                            onClose="testClose"
                            class="form-control compact_form_flatpickr"
                            :config="dateConfig"
                            :state="errors.length > 0 ? false : null"
                            @on-change="
                              checkTraSettlCptyDate('Cpty Order Date')
                            "
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cpty order time-->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.cpty_order_time')"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cpty_order_time')"
                          rules="required|min:8"
                        >
                          <cleave
                            id="orderTime"
                            v-model="tradeForm.cptyOrderTime"
                            class="form-control compact_form_cleave"
                            :raw="false"
                            :options="cleaveOptions.time"
                            :placeholder="
                              $t('equity_bond.trade_form.cpty_order_time')
                            "
                            :state="errors.length > 0 ? false : null"
                            @blur.native="checkTime('Execution Time')"
                          />

                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Stop Price -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6"
                      v-if="
                        tradeForm.orderType == 'Stop' ||
                          tradeForm.orderType == 'Trailing Stop'
                      "
                    >
                      <b-form-group
                        :label="$t('equity_bond.trade_form.stop_price')"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.stop_price')"
                          :rules="
                            tradeForm.orderType == 'Stop'
                              ? 'required|min_value:0'
                              : '' || tradeForm.orderType == 'Trailing Stop'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <cleave
                            id="stopPrice"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.stopPrice"
                            class="form-control compact_form_cleave"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('equity_bond.trade_form.stop_price')
                            "
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Trailing Amount -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6"
                      v-if="tradeForm.orderType == 'Trailing Stop'"
                    >
                      <b-form-group
                        :label="$t('equity_bond.trade_form.trailing_amount')"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.trailing_amount')"
                          :rules="
                            tradeForm.orderType == 'Trailing Stop'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <b-form-input
                            id="trailingAmount"
                            v-model="tradeForm.trailingAmount"
                            name="trailingAmount"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('equity_bond.trade_form.trailing_amount')
                            "
                            class="compact_form-input"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- OCO orders inputs-->
                  <b-row>
                    <!-- enable connected OCO orders-->
                    <b-col cols="12" xl="2" lg="2" md="3" sm="12" class="mt-1">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="enable OCO orders"
                          >
                            <b-form-checkbox
                              v-model="tradeForm.enableConnectedOCOOrders"
                              plain
                              class="compact_form_checkbox"
                            >
                              {{ $t("equity_bond.trade_form.enable_oco") }}
                            </b-form-checkbox>
                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Take Profit Limit -->
                      <b-col cols="4" xl="2" lg="2" md="3" sm="4">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.take_profit_limit')
                          "
                          v-if="tradeForm.enableConnectedOCOOrders"
                          class="compact_form_label"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('equity_bond.trade_form.take_profit_limit')
                            "
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="takeProfitLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.takeProfitLimit"
                              class="form-control compact_form_cleave"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.take_profit_limit')
                              "
                              @blur.native="
                                checkEnableOCOInputs('takeProfitLimit')
                              "
                            />

                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Stop Loss Stop -->
                      <b-col cols="4" xl="2" lg="2" md="3" sm="4">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.stop_loss_stop')"
                          v-if="tradeForm.enableConnectedOCOOrders"
                          class="compact_form_label"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.stop_loss_stop')"
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="stopLossStop"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.stopLossStop"
                              class="form-control compact_form_cleave"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.stop_loss_stop')
                              "
                              @blur.native="
                                checkEnableOCOInputs('stopLossStop')
                              "
                            />
                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Stop Loss Limit -->
                      <b-col cols="4" xl="2" lg="2" md="3" sm="4">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.stop_loss_limit')"
                          v-if="tradeForm.enableConnectedOCOOrders"
                          class="compact_form_label"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.stop_loss_limit')"
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="stopLossLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.stopLossLimit"
                              class="form-control compact_form_cleave"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.stop_loss_limit')
                              "
                              @blur.native="
                                checkEnableOCOInputs('stopLossLimit')
                              "
                            />
                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                  </b-row>
                </b-card>
              </b-row>

              <b-row>
              <!-- /******************************* Trade Date & Time && Security Details***************************************************************** */ -->

                <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="pl-0" style="padding-right:0.5rem!important">

                  <!-- /******************************* Security Details***************************************************************** */ -->
                  <b-card
                    :title="$t('equity_bond.titles.security_details')"
                    class="compact_form_card"
                  >

                  <b-row>

                    <!-- cost center -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.cost_center')"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cost_center')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.costCenter"
                            :options="optCostCenters"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_cost_center')
                            "
                            :reduce="(val) => val.CostCenter"
                            label="CostCenter"
                            class="compact_form_vue-select"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- security desc-->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.security_desc')"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.security_desc')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.securityDesc"
                            :options="optSecurityDescriptions"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_security_desc')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :loading="loadingSecurityDesc"
                            @option:selecting="selectedSecuritydesc"
                            class="compact_form_vue-select"
                          />

                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                        <!-- @search="
                              (search, loading) => {
                                loading(false);
                                searchSecuritydesc(search);
                              }
                            " -->
                      </b-form-group>
                    </b-col>

                    <!-- ISIN -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group class="compact_form_label" :label="$t('equity_bond.trade_form.isin')">
                        <validation-provider #default="{ errors }" name="ISIN">
                          <b-form-input
                            id="isin"
                            v-model="tradeForm.isin"
                            :name="$t('equity_bond.trade_form.isin')"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('equity_bond.trade_form.isin')"
                            :disabled="true"
                            class="compact_form-input"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- quantity -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.quantity')"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.quantity')"
                          rules="required|min_value:0"
                        >
                          <cleave
                            id="quantity"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.quantity"
                            class="form-control compact_form_cleave"
                            :raw="true"
                            :options="cleaveQuantityOptions.number"
                            :placeholder="$t('equity_bond.trade_form.quantity')"
                            @blur.native="refreshAmount(true)"
                          />

                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- client price -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.client_price')"
                          class="compact_form_label"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.client_price')"
                            rules="required|min_value:0"
                          >
                            <cleave
                              id="clientPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientPrice"
                              class="form-control compact_form_cleave"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.client_price')
                              "
                              @blur.native="refreshAmount(true)"
                            />
                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- client price type ccy -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6" >
                        <b-form-group
                          :label="$t('equity_bond.trade_form.price_type')"
                          class="compact_form_label"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.price_type')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.clientPriceType"
                              :options="optClientPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('equity_bond.trade_form.price_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              class="compact_form_vue-select"
                            />
                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    <!-- amount -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.amount')"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.amount')"
                        >
                          <cleave
                            id="amount"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.amount"
                            class="form-control compact_form_cleave"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="$t('equity_bond.trade_form.amount')"
                            :disabled="true"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- exchange traded -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.exchange_traded')"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.exchange_traded')"
                          :rules="'required'"
                        >
                        <div class="d-flex align-items-center" >
                          <v-select
                            v-model="tradeForm.exchangeTraded"
                            :options="optExchangeTrades"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'equity_bond.trade_form.select_exchange_traded'
                              )
                            "
                            :reduce="(val) => val"
                            :taggable="true"
                            :loading="exchangeSelectBoxLoading"
                            @option:created="createNewExchangeTraded"
                            class="compact_form_vue-select"
                            style="width:100%"
                          />
                          <i class="fa-solid fa-circle-info input-dropdown animate__animated animate__fadeIn"
                          style="padding-left:0.2rem"
                          v-b-tooltip.hover.top="'After entering the exchange traded you want to add, press enter.'"
                          ></i>
                          </div>
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>


                    <!-- client -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.client')"
                        class="compact_form_label"
                      >
                        <div class="d-flex align-items-center">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.client')"
                            rules="required"
                            style="width: 100%;"
                          >
                            <v-select
                              v-model="tradeForm.client"
                              :options="optClients"
                              :clearable="false"
                              :placeholder="
                                $t('equity_bond.trade_form.select_client')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :loading="loadingClient"
                              :filterable="true"
                              @option:selecting="selectedClient"
                              class="compact_form_vue-select"
                            />
                            <!-- @search="
                                (search, loading) => {
                                  loading(false);
                                  searchClient(search);
                                }
                              " -->
                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                          <b-dropdown
                            variant="link"
                            no-caret
                            :right="$store.state.appConfig.isRTL"
                            class="input-dropdown animate__animated animate__fadeIn"
                            v-if="this.searchedClients.length > 0"
                          >
                            <template #button-content class="p-1">
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                              />
                            </template>

                            <b-dropdown-item @click="seeFilteredClientModal">
                              <feather-icon icon="EyeIcon" />
                              <span class="align-middle ml-50">{{
                                $t("general_text.see_verified_clients")
                              }}</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </b-form-group>
                    </b-col>
                    <!-- client account -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.client_account')"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.client_account')"
                          rules="required"
                        >
                          <b-form-input
                            id="clientAccount"
                            v-model="tradeForm.clientAccount"
                            name="clientAccount"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('equity_bond.trade_form.client_account')
                            "
                            class="compact_form-input"
                            :disabled="true"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    
                    <!-- counter party -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.counterparty')"
                        class="compact_form_label"
                      >
                        <div class="d-flex align-items-center">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.counterparty')"
                            style="width: 100%;"
                          >
                            <v-select
                              v-model="tradeForm.counterparty"
                              :options="optCounterParties"
                              :clearable="false"
                              :placeholder="
                                $t('equity_bond.trade_form.select_counterparty')
                              "
                              :reduce="(val) => val"
                              :loading="loadingCounterparty"
                              :filterable="true"
                              @option:selecting="selectedCounterparty"
                              class="compact_form_vue-select"
                            />
                            <!-- @search="
                              (search, loading) => {
                                loading(false);
                                searchCounterparty(search);
                              }
                            " -->
                            <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                          </validation-provider>
                          <b-dropdown
                            variant="link"
                            no-caret
                            :right="$store.state.appConfig.isRTL"
                            class="input-dropdown animate__animated animate__fadeIn"
                            v-if="this.searchedCounterparties.length > 0"
                          >
                            <template #button-content class="p-1">
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                              />
                            </template>

                            <b-dropdown-item
                              @click="seeFilteredCounterpartyModal"
                            >
                              <feather-icon icon="EyeIcon" />
                              <span class="align-middle ml-50">{{
                                $t("general_text.see_verified_clients")
                              }}</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </b-form-group>
                    </b-col>
                    <!-- cpty account -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.cpty_account')"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cpty_account')"
                        >
                          <b-form-input
                            id="isin"
                            v-model="tradeForm.cptyAccount"
                            name="cpty account"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('equity_bond.trade_form.cpty_account')
                            "
                            class="compact_form-input"
                            :disabled="true"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- counterparty price -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.counterparty_price')"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('equity_bond.trade_form.counterparty_price')
                          "
                          :rules="'required'"
                        >
                          <cleave
                            id="counterpartyPrice"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.counterpartyPrice"
                            class="form-control compact_form_cleave"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('equity_bond.trade_form.counterparty_price')
                            "
                            @blur.native="calculateCptyPrice"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- counter party trader -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t('equity_bond.trade_form.counterparty_trader')
                        "
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('equity_bond.trade_form.counterparty_trader')
                          "
                        >
                          <v-select
                            v-model="tradeForm.counterpartyTrader"
                            :options="optCounterPartyTraders"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'equity_bond.trade_form.select_counterparty_trader'
                              )
                            "
                            :reduce="(val) => val"
                            class="compact_form_vue-select"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- sfc user -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.sfc_user')"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.sfc_user')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.scfUser"
                            :options="optScfUsers"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_sfc_user')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            class="compact_form_vue-select"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- acting capacity -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.acting_capacity')"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.acting_capacity')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.actingCapaticy"
                            :options="optActingCapacities"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'equity_bond.trade_form.select_acting_capacity'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            class="compact_form_vue-select"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Clean-->
                    <b-col cols="6" xl="1" lg="1" md="4" sm="6" class="mt-1">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="clean"
                        >
                          <b-form-checkbox 
                          v-model="tradeForm.clean"
                          plain
                          class="compact_form_checkbox"
                          >
                          <b>{{ $t("equity_bond.trade_form.clean") }}</b>
                          </b-form-checkbox>
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- accrued -->
                    <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.accrued') + '*'"
                        v-if="tradeForm.clean"
                        class="compact_form_label"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.accrued')"
                          :rules="tradeForm.clean ? 'required' : ''"
                        >
                          <cleave
                            id="accrued"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.accrued"
                            class="form-control compact_form_cleave"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('equity_bond.trade_form.accrued')
                            "
                            @blur.native="calculateAccrued"
                          />
                          <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  </b-card>

                  <!-- /******************************* Trade Date & Time***************************************************************** */ -->
                  <b-card
                      :title="$t('equity_bond.titles.trade_date_time')"
                      class="compact_form_card"
                    >
                    <b-row>
                        <!-- Trade Date -->
                        <b-col cols="4" xl="3" lg="3" md="5" sm="4">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.trade_date')"
                            class="compact_form_label"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.trade_date')"
                            >
                              <b-input-group
                                class="compact_form_input-group"
                                :class="
                                  tradeForm.tradeDateName == 'Sunday' ||
                                  tradeForm.tradeDateName == 'Saturday'
                                    ? 'tradeDateName'
                                    : ''
                                "
                                :append="tradeForm.tradeDateName"
                              >
                                <flat-pickr
                                  :placeholder="
                                    $t('equity_bond.trade_form.trade_date')
                                  "
                                  v-model="tradeForm.tradeDate"
                                  onClose="testClose"
                                  class="form-control compact_form_flatpickr"
                                  :config="tradeDateConfig"
                                  @on-change="
                                    checkTraSettlCptyDate('Trade Date', true)
                                  "
                                />
                              </b-input-group>
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- T+ -->
                        <b-col cols="4" xl="1" lg="1" md="2" sm="4"
                          style="padding-left: 0.5rem !important; padding-right:0.5rem !important"
                        >
                          <b-form-group label="T+" class="compact_form_label">
                            <validation-provider
                              #default="{ errors }"
                              name="T+"
                              rules="min_value:0"
                            >
                              <b-form-input
                                id="T+"
                                v-model.number="tradeForm.Tplus"
                                name="T+"
                                placeholder="T+"
                                @change="arrangeTPlus"
                                type="number"
                                class="compact_form-input"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Settlement Date -->
                        <b-col cols="4" xl="3" lg="3" md="5" sm="4">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.settlement_date')
                            "
                            class="compact_form_label"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.settlement_date')
                              "
                            >
                              <b-input-group
                                class="compact_form_input-group"
                                :class="
                                  tradeForm.settlementDateName == 'Sunday' ||
                                  tradeForm.settlementDateName == 'Saturday'
                                    ? 'settlementDateName'
                                    : ''
                                "
                                :append="tradeForm.settlementDateName"
                              >
                                <flat-pickr
                                  :placeholder="
                                    $t('equity_bond.trade_form.settlement_date')
                                  "
                                  v-model="tradeForm.settlementDate"
                                  onClose="testClose"
                                  class="form-control compact_form_flatpickr"
                                  :config="dateConfig"
                                  @on-change="
                                    checkTraSettlCptyDate('Settlement Date')
                                  "
                                />
                              </b-input-group>
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- execution time -->
                        <b-col cols="6" xl="2" lg="2" md="6" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.execution_time')"
                            class="compact_form_label"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.execution_time')
                              "
                              rules="required|min:8"
                            >
                              <cleave
                                id="executionTime"
                                v-model="tradeForm.executionTime"
                                class="form-control compact_form_cleave"
                                :raw="false"
                                :options="cleaveOptions.time"
                                :placeholder="
                                  $t('equity_bond.trade_form.execution_time')
                                "
                                :state="errors.length > 0 ? false : null"
                                @blur.native="checkTime('Execution Time')"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- cpty execution time -->
                        <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.cpty_execution_time')
                            "
                            class="compact_form_label"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.cpty_execution_time')
                              "
                            >
                              <cleave
                                id="cptyExecutionTime"
                                v-model="tradeForm.cptyExecutionTime"
                                class="form-control compact_form_cleave"
                                :raw="false"
                                :options="cleaveOptions.time"
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.cpty_execution_time'
                                  )
                                "
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>

                </b-col>

                <!-- /******************************* Trade Details && Settlement Details ***************************************************************** */ -->

                <b-col cols="12" sm="12" md="12" lg="6" xl="6" class="pr-0" style="padding-left:0.5rem!important">

                  <!-- /******************************* Trade Details***************************************************************** */ -->

                  <b-card
                      :title="$t('equity_bond.titles.trade_details')"
                      class="compact_form_card"
                    >
                    <b-row>
                        <!-- Client Rate -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.client_rate')"
                            class="compact_form_label"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.client_rate')"
                              :rules="
                                tradeForm.calculateCommissionFromRate1
                                  ? 'min_value:0'
                                  : ''
                              "
                            >
                              <b-input-group append="Bps" class="compact_form_input-group">
                                <b-input-group-prepend is-text>
                                  <b-form-checkbox
                                    plain
                                    name="checkbox-input"
                                    v-model="
                                      tradeForm.calculateCommissionFromRate1
                                    "
                                    v-b-tooltip.hover.top
                                    :title="
                                      $t(
                                        'equity_bond.trade_form.client_rate_tooltip'
                                      )
                                    "
                                  >
                                  </b-form-checkbox>
                                </b-input-group-prepend>

                                <cleave
                                  id="clientRate"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.clientRate"
                                  class="form-control compact_form_cleave"
                                  :raw="true"
                                  :options="cleave6DecimalOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.client_rate')
                                  "
                                  :disabled="
                                    !tradeForm.calculateCommissionFromRate1
                                  "
                                  @blur.native="calculateClientRateToComission"
                                />
                              </b-input-group>

                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Client Commission -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.client_comission')
                            "
                            class="compact_form_label"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.client_comission')
                              "
                              :rules="
                                !tradeForm.calculateCommissionFromRate1
                                  ? 'min_value:0'
                                  : ''
                              "
                            >
                              <cleave
                                id="clientCommission"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientCommission"
                                class="form-control compact_form_cleave"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('equity_bond.trade_form.client_comission')
                                "
                                :disabled="
                                  tradeForm.calculateCommissionFromRate1
                                "
                                @blur.native="calculateClientRate"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Client amount -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.client_amount')"
                            class="compact_form_label"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.client_amount')"
                              rules="min_value:0"
                            >
                              <cleave
                                id="clientAmmount"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientAmount"
                                class="form-control compact_form_cleave"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('equity_bond.trade_form.client_amount')
                                "
                                :disabled="true"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Counterparty Rate -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.counterparty_rate')
                            "
                            class="compact_form_label"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.counterparty_rate')
                              "
                              :rules="
                                tradeForm.calculateCommissionFromRate2
                                  ? 'min_value:0'
                                  : ''
                              "
                            >
                              <b-input-group append="Bps" class="compact_form_input-group">
                                <b-input-group-prepend is-text>
                                  <b-form-checkbox
                                    plain
                                    name="checkbox-input"
                                    v-model="
                                      tradeForm.calculateCommissionFromRate2
                                    "
                                    v-b-tooltip.hover.top
                                    :title="
                                      $t(
                                        'equity_bond.trade_form.counterparty_rate_tooltip'
                                      )
                                    "
                                  />
                                </b-input-group-prepend>

                                <cleave
                                  id="counterpartyRate"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.counterpartyRate"
                                  class="form-control compact_form_cleave"
                                  :raw="true"
                                  :options="cleave6DecimalOptions.number"
                                  :placeholder="
                                    $t(
                                      'equity_bond.trade_form.counterparty_rate'
                                    )
                                  "
                                  :disabled="
                                    !tradeForm.calculateCommissionFromRate2
                                  "
                                  @blur.native="
                                    calculateCounterpartyRateToComission
                                  "
                                />
                              </b-input-group>

                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- cpty comission -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.cpty_comission')"
                            class="compact_form_label"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.cpty_comission')
                              "
                              :rules="
                                !tradeForm.calculateCommissionFromRate2
                                  ? 'min_value:0'
                                  : ''
                              "
                            >
                              <cleave
                                id="cptyComission"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.cptyCommission"
                                class="form-control compact_form_cleave"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('equity_bond.trade_form.cpty_comission')
                                "
                                :disabled="
                                  tradeForm.calculateCommissionFromRate2
                                "
                                @blur.native="calculateCounterpartyRate"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- counterparty amount -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.counterparty_amount')
                            "
                            class="compact_form_label"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.counterparty_amount')
                              "
                              rules="min_value:0"
                            >
                              <cleave
                                id="counterpartyAmount"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.counterpartyAmount"
                                class="form-control compact_form_cleave"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.counterparty_amount'
                                  )
                                "
                                :disabled="true"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- profit -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.profit')"
                            class="compact_form_label"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.profit')"
                            >
                              <cleave
                                id="profit"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.profit"
                                class="form-control compact_form_cleave"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('equity_bond.trade_form.profit')
                                "
                                :disabled="true"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- order taken via -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.order_taken_via')
                            "
                            class="compact_form_label"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.order_taken_via')
                              "
                            >
                              <v-select
                                v-model="tradeForm.orderTakenVia"
                                :options="optOrderTakenVias"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.select_order_taken_via'
                                  )
                                "
                                :reduce="(val) => val"
                                class="compact_form_vue-select"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- order taken through -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.order_given_through')
                            "
                            class="compact_form_label"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.order_given_through')
                              "
                            >
                              <v-select
                                v-model="tradeForm.orderGivenThrough"
                                :options="optOrderGivenThroughs"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.select_order_given_through'
                                  )
                                "
                                :reduce="(val) => val"
                                class="compact_form_vue-select"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- note -->
                        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                          <b-form-group
                          class="compact_form_label"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.note')"
                            >
                              <b-form-textarea
                                id="textarea-no-resize"
                                :placeholder="$t('equity_bond.trade_form.note')"
                                no-resize
                                rows="3"
                                v-model="tradeForm.note"
                                class="compact_form-textarea"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>
                  

                  <!-- /******************************* Settlement Details***************************************************************** */ -->
                  <b-card
                      :title="$t('equity_bond.titles.settlement_details')"
                      class="compact_form_card"
                    >
                    <b-row>
                        <!-- UTI  -->
                        <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.uti')"
                            class="compact_form_label"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.uti')"
                            >
                              <b-form-input
                                id="UTI"
                                v-model="tradeForm.uti"
                                name="UTI"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('equity_bond.trade_form.uti')"
                                class="compact_form-input"
                              />

                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Unique Link ID -->
                        <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.unique_link_id')"
                            class="compact_form_label"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.unique_link_id')
                              "
                            >
                              <b-form-input
                                id="uniqueLinkID"
                                v-model.number="tradeForm.uniqueLinkID"
                                type="number"
                                name="uniqueLinkID"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('equity_bond.trade_form.unique_link_id')
                                "
                                class="compact_form-input"
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      <div>
                        <!-- Trade Ref -->
                        <!-- <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.trade_ref')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.trade_ref')"
                            >
                              <b-form-input
                                :disabled="true"
                                id="tradeRef"
                                v-model="tradeForm.tradeRef"
                                name="tradeRef"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('equity_bond.trade_form.trade_ref')"
                                autofocus
                              />
                              <small class="text-danger compact_form-validation-message">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->
                      </div>
                    </b-row>
                    </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" />
              <b>{{ $t("equity_bond.overlay_messages.creating_message") }}</b>
            </p>
          </div>
        </template>
      </b-overlay>
      <!-- modal footer -->
      <template #modal-footer >
        <b-row class="align-items-center" style="flex: 1;">
          <!-- save default entry -->
          <b-col
            cols="12" md="12" xl="6" lg="6" sm="12"
            class="d-flex justify-content-start align-items-center"
          >

            <b-overlay
          :show="loadingDefaultEntry"
          rounded="lg"
          opacity="0.6"
          class="d-flex align-items-center"
        >
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner
            small
            type="grow"
            variant="secondary"
          />
          <b-spinner
            type="grow"
            variant="dark"
          />
          <b-spinner
            small
            type="grow"
            variant="secondary"
          />
          <!-- We add an SR only text for screen readers -->
          <span class="sr-only">Please wait...</span>
        </div>
      </template>
      
      <b-form-checkbox
          v-model="showDefaultEntry"
          class="float-right compact_form_checkbox"
          style="margin-right:0.8rem"
          plain
        >
          {{ $t("general_title.default_entry_title") }}
        </b-form-checkbox>
      <validation-observer
              ref="tradeDefaultEntryValidation"
              style="margin-right:0.5rem"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('general_title.default_entry_name')"
                :rules="showDefaultEntry ? 'required|min:3' : ''"
              >
                <b-form-input
                  v-if="showDefaultEntry"
                  id="defaultEntryName"
                  v-model="defaultEntryName"
                  name="defaultEntryName"
                  class="float-right animate__animated animate__fadeIn compact_form-input"
                  type="text"
                  :placeholder="$t('general_title.default_entry_name')"
                  autofocus
                  :state="errors.length > 0 ? false : null"
                />
              </validation-provider>
            </validation-observer>

            <b-button
              @click="saveDefaultEntry"
              v-if="showDefaultEntry"
              variant="gradient-info"
              size="sm"
              class="animate__animated animate__fadeIn"
            >
              {{ $t("general_title.default_entry_title") }}
            </b-button>
        </b-overlay>

          </b-col>

          <!-- trade ok operation buttons -->
          <b-col cols="12" md="12" xl="6" lg="6" sm="12" class="d-flex justify-content-end align-items-center">

            <b-dropdown
              :text="$t('general_title.default_entries')"
              dropup
              right
              class="ml-1 animate__animated animate__fadeIn"
              variant="dark"
              size="sm"
              v-if="optDefaultEntries.length > 0"
            >
              <b-dropdown-item
                v-for="(d, index) in optDefaultEntries"
                :key="index"
                class="d-flex align-items-center"
                >
                <feather-icon
                class="mr-1"
                style="color:red;"
                @click="deleteSavedDefault(d.id)"
                icon="XIcon"
                size="12"
              />
               <span  @click="selectDefaultData(d)" style="font-size:0.8rem;font-weight: 400;"> {{ d.DefaultEntryName }} </span> </b-dropdown-item>
            </b-dropdown>

            
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="ml-1"
              @click="resetTradeModal"
            >
              {{ $t('equity_bond.modals.modal_cancel') }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="ml-1"
              @click="tradeOkOperation"
            >
              {{ $t('equity_bond.modals.create_trade_modal_ok') }}
            </b-button>

          </b-col>
        </b-row>
      </template>
      {{/****END*** enter new trade modal*/}}
    </b-modal>
    {{/** view filtered client modal*/}}
    <b-modal
      id="clientModal"
      v-model="viewFilteredClientModal"
      :title="$t('client_table_modal.filtered_clients')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetClientModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      scrollable
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedClients"
                selectable
                select-mode="single"
                @row-clicked="selectedClientFromTable"
                responsive
                head-variant="light"
                :fields="clientTableColumns"
                show-empty
                :empty-text="
                  $t('equity_bond.general_messages.no_matching_recors_found')
                "
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered modal*/}}
    </b-modal>
    {{/** view filtered counterparty modal*/}}
    <b-modal
      id="counterpartyModal"
      v-model="viewFilteredCounterpartyModal"
      :title="$t('client_table_modal.filtered_counterparties')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetCounterpartyModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      scrollable
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedCounterparties"
                selectable
                select-mode="single"
                @row-clicked="selectedCounterpartyFromTable"
                responsive
                head-variant="light"
                :fields="clientTableColumns"
                show-empty
                :empty-text="
                  $t('equity_bond.general_messages.no_matching_recors_found')
                "
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered counterparty modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  BFormTimepicker,
  VBTooltip,
  BTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import bondStoreModule from "./BondStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  alphaNum,
  email,
  max,
  max_value,
  min_value,
} from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BFormTimepicker,
    BTooltip,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const EQUITYBONDS_APP_STORE_MODULE_NAME = "equity&bond";
    // Register module
    if (!store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
      store.registerModule(EQUITYBONDS_APP_STORE_MODULE_NAME, bondStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
        store.unregisterModule(EQUITYBONDS_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {
  },
  props: {
    searchedCounterparties: {
      type: Array,
      default: function() {
        return [];
      },
    },
    searchedClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCostCenters: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCounterParties: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },

  data() {
    return {
      allEquityBondsData: [],
      enterNewTradeModal: false,
      tradeModalLoading: false,
      loadingSecurityDesc: false,
      loadingClient: false,
      loadingCounterparty: false,
      timerSecuritydesc: null,
      orderDurationDataDisabled: true,
      orderDurationTimeDisabled: true,
      orderDurationInputsVisible: true,
      exchangeSelectBoxLoading: false,
      showDefaultEntry: false,
      defaultEntryName: null,
      loadingDefaultEntry: false,
      optDefaultEntries: [],
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optEntryTypes: ["Order", "Execution"],
      optbuySells: ["Buy", "Sell", "Sell Short"],
      optOrderTypes: ["Limit", "Market", "Stop Limit", "Stop", "Trailing Stop"],
      optTimeInForces: ["DAY", "GTC", "GTD", "FOK", "FAK"],
      optInstrumentTypes: ["EQ", "FI"],
      optSecurityDescriptions: [],

      optClientPriceTypes: [],
      optExchangeTrades: [],
      optScfUsers: [],
      optActingCapacities: ["PRINCIPAL", "AGENT"],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      optCounterPartyTraders: [],
      fetchedTradeData: {},
      checkedHolidayDate: null,
      commissions: [],

      tradeForm: {
        costCenter: null,
        orderDurationDate: moment().format("DD-MM-YYYY "),
        orderDurationDateName: null,
        orderDurationTime: moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString(),
        entryType: "Order",
        buySell: "Buy",
        orderType: "Limit",
        timeInForce: "DAY",
        orderDate: moment().format("DD-MM-YYYY"),
        orderDateName:null,
        orderTime: moment().format("HH:mm:ss"),
        cptyOrderDate: null,
        cptyOrderTime: null,
        enableConnectedOCOOrders: false,
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        instrumentType: "FI",
        prop: false,
        securityDesc: null,
        quantity: null,
        clientPrice: null,
        clientPriceType: null,
        amount: null,
        counterpartyPrice: null,
        clean: false,
        accrued: null,
        client: null,
        clientAccount: null,
        isin: null,
        exchangeTraded: null,
        counterparty: null,
        cptyAccount: null,
        counterpartyTrader: null,
        scfUser: null,
        actingCapaticy: "PRINCIPAL",
        tradeDate: null,
        tradeDateName: null,
        defTradeDateName:null,
        settlementDate: null,
        settlementDateName: null,
        Tplus: null,
        executionTime: null,
        cptyExecutionTime: null,
        clientRate: null,
        clientCommission: null,
        clientAmount: null,
        counterpartyRate: null,
        cptyCommission: null,
        counterpartyAmount: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        profit: null,
        tradeReportingNeeded: null,
        note: null,
        calculateCommissionFromRate1: true,
        calculateCommissionFromRate2: true,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i:s",
        time_24hr: true,
      },
      dateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
      },
      orderDateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
        plugins:[
          ShortcutButtonsPlugin({
            button: [
              {
                label: "Yesterday"
              },
              {
                label: "Today"
              },
              {
                label: "Tomorrow"
              }
            ],
            label: "or",
            onClick: (index, fp) => {
              let date;
              switch (index) {
                case 0:
                  date = new Date(Date.now() - 24 * 60 * 60 * 1000);
                  this.tradeForm.orderDateName = "Yesterday"
                  break;
                case 1:
                  date = new Date();
                  this.tradeForm.orderDateName = "Today"
                  break;
                case 2:
                  date = new Date(Date.now() + 24 * 60 * 60 * 1000);
                  this.tradeForm.orderDateName = "Tomorrow"
                  break;
              }
              fp.setDate(date);

            },
          })
        ],
      },
      orderDurationDateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
        plugins:[
          ShortcutButtonsPlugin({
            button: [
              {
                label: "Yesterday"
              },
              {
                label: "Today"
              },
              {
                label: "Tomorrow"
              }
            ],
            label: "or",
            onClick: (index, fp) => {
              let date;
              switch (index) {
                case 0:
                  date = new Date(Date.now() - 24 * 60 * 60 * 1000);
                  this.tradeForm.orderDurationDateName = "Yesterday"
                  break;
                case 1:
                  date = new Date();
                  this.tradeForm.orderDurationDateName = "Today"
                  break;
                case 2:
                  date = new Date(Date.now() + 24 * 60 * 60 * 1000);
                  this.tradeForm.orderDurationDateName = "Tomorrow"
                  break;
              }
              fp.setDate(date);
            },
          })
        ],
      },
      tradeDateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
        plugins:[
          ShortcutButtonsPlugin({
            button: [
              {
                label: "Yesterday"
              },
              {
                label: "Today"
              },
              {
                label: "Tomorrow"
              }
            ],
            label: "or",
            onClick: (index, fp) => {
              let date;
              switch (index) {
                case 0:
                  date = new Date(Date.now() - 24 * 60 * 60 * 1000);
                  this.tradeForm.defTradeDateName = "Yesterday"
                  break;
                case 1:
                  date = new Date();
                  this.tradeForm.defTradeDateName = "Today"
                  break;
                case 2:
                  date = new Date(Date.now() + 24 * 60 * 60 * 1000);
                  this.tradeForm.defTradeDateName = "Tomorrow"
                  break;
              }

              fp.setDate(date);

            },
          })
        ],
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 2,
          // novos
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
          // fim novos
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
     
      },

      cleave6DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 6,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
      },
      cleaveQuantityOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 0,
          numeralIntegerScale: 0,
        },
      },
      viewFilteredClientModal: false,
      viewFilteredCounterpartyModal: false,
      clientTableColumns: [
        {
          key: "AccountName",
          label: this.$t("client_table_modal.account_name"),
          class: "text-center",
        },
        {
          key: "AccountNumber",
          label: this.$t("client_table_modal.account_code"),
          sortable: false,
          class: "text-center",
        },
        {
          key: "SettlementType",
          label: this.$t("client_table_modal.settlement_type"),
          sortable: false,
          class: "text-center",
        },
      ],
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchBond"]),
    saveDefaultEntry() {
      this.$refs.tradeDefaultEntryValidation.validate().then((success) => {
        if (success) {
          // set will be save default entry data
          if (this.showDefaultEntry) {
            this.loadingDefaultEntry = true;

            var defaultEntryData = {
              DefaultEntryName: this.defaultEntryName,
              DefaultEntryData: this.tradeForm,
              DefaultType: "Bond",
              UserId: this.user.id,
            };

            store
              .dispatch("equity&bond/saveDefaultEntry", {
                defaultEntryData: this.showDefaultEntry
                  ? defaultEntryData
                  : null,
              })
              .then((res) => {
                if (res.data.info == "success") {
                  this.defaultEntryName = null;
                  this.saveDefaultEntrySuccessToastMessage();
                  this.getSavedDefaultEntries();
                  this.loadingDefaultEntry = false;
                } else if (res.data.info == "existing") {
                  this.loadingDefaultEntry = false;
                  this.errorMessage(res.data.message);
                } else {
                  this.loadingDefaultEntry = false;
                  this.errorMessage(res.data.message);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      });
    },
    selectDefaultData(data) {
      if (data.DefaultEntryData) {
        this.tradeForm = data.DefaultEntryData;
        this.changeTimeInForceType();
        
      
        if(data.DefaultEntryData.orderDateName && data.DefaultEntryData.orderDateName != null) {
          if(data.DefaultEntryData.orderDateName == "Yesterday"){
            this.tradeForm.orderDate = this.dateFormat(new Date(Date.now() - 24 * 60 * 60 * 1000))
          } else if(data.DefaultEntryData.orderDateName == "Tomorrow"){
            this.tradeForm.orderDate = this.dateFormat(new Date(Date.now() + 24 * 60 * 60 * 1000))
          } else if(data.DefaultEntryData.orderDateName == "Today"){
            this.tradeForm.orderDate = this.dateFormat(Date.now())
          }
        }
        
        if(data.DefaultEntryData.orderDurationDateName && data.DefaultEntryData.orderDurationDateName != null) {
          if(data.DefaultEntryData.orderDurationDateName == "Yesterday"){
            this.tradeForm.orderDurationDate = this.dateFormat(new Date(Date.now() - 24 * 60 * 60 * 1000))
          } else if(data.DefaultEntryData.orderDurationDateName == "Tomorrow"){
            this.tradeForm.orderDurationDate = this.dateFormat(new Date(Date.now() + 24 * 60 * 60 * 1000))
          } else if(data.DefaultEntryData.orderDurationDateName == "Today"){
            this.tradeForm.orderDurationDate = this.dateFormat(Date.now())
          }
        }

        if(data.DefaultEntryData.defTradeDateName && data.DefaultEntryData.defTradeDateName != null) {
          if(data.DefaultEntryData.defTradeDateName == "Yesterday"){
            this.tradeForm.tradeDate = this.dateFormat(new Date(Date.now() - 24 * 60 * 60 * 1000))
          } else if(data.DefaultEntryData.defTradeDateName == "Tomorrow"){
            this.tradeForm.tradeDate = this.dateFormat(new Date(Date.now() + 24 * 60 * 60 * 1000))
          } else if(data.DefaultEntryData.defTradeDateName == "Today"){
            this.tradeForm.tradeDate = this.dateFormat(Date.now())
          }
        }
      }
    },

    getSavedDefaultEntries() {
      store
        .dispatch("equity&bond/getSavedDefaultEntries", {
          DefaultType: "Bond",
          UserId: this.user.id,
        })
        .then((res) => {
          this.optDefaultEntries = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteSavedDefault(id) {
      store
        .dispatch("equity&bond/deleteSavedDefault", {
          auth: this.user,
          id: id,
        })
        .then((res) => {
          this.searchName = null;
          this.getSavedDefaultEntries();

          this.successMessage(res.data.message);
        })
        .catch((error) => {
          this.errorMessage(error);

          console.log(error);
        });
    },

    getArangeEquityBondTCUsers() {
      store
        .dispatch("equity&bond/fetchArangeEquityBondTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optScfUsers = res.data.users;
            this.optClientPriceTypes = res.data.currencies;
          } else if (res.data.info == "no_users") {
            this.errorMessage(
              this.$t("equity_bond.messages.user_error_no_users")
            );
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeEquityBondOrderTakenGivenThrough() {
      store
        .dispatch(
          "equity&bond/fetchArrangeEquityBondOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          } else {
            this.errorMessage(
              this.$t("equity_bond.messages.not_found_given_order_data")
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeEquityBondCurrencies() {
      store
        .dispatch("equity&bond/fetchArrangeEquityBondCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optClientPriceTypes = res.data.currencies;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.tradeValidation.validate().then((success) => {
        if (success) {
          this.$checkAccessRight("EquityBond", "Save").then((res) => {
            if (res.data.valid) {
              this.tradeModalLoading = true;
              //set and check trade data
              let tradeData = this.setTradeData();

              store
                .dispatch("equity&bond/createNewEntry", {
                  auth: this.user,
                  tradeData: tradeData,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    (this.enterNewTradeModal = false),
                      this.$emit("createdEquityBond", true);
                    this.createTradeToastMessage();
                    this.resetTradeModal();
                    this.tradeModalLoading = false;
                  } else {
                    this.errorMessage(res.data.message);
                    this.tradeModalLoading = false;
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.enterNewTradeModal = false;
              this.notAuthToastMessage();
            }
          });
        }
      });
    },
    createNewExchangeTraded(value) {
      this.exchangeSelectBoxLoading = true;

      if(this.tradeForm.securityDesc != null) {
        axiosIns
              .post(`saveExchange`, {
                security: this.tradeForm.securityDesc,
                exchange: value,
              })
              .then((res) => {
         
              })
              .catch((error) => reject(error));
      }
      this.optExchangeTrades.push(value)

      this.tradeForm.exchangeTraded = value;
      this.exchangeSelectBoxLoading = false;
    },
    searchSecuritydesc(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingSecurityDesc = true;
          let securityTypeVal = "Bond";

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchSecuritydesc`, {
                auth: this.user,
                search: search,
                type: securityTypeVal,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optSecurityDescriptions = [];
                  this.searchedSecurityDesc = [];
                  this.searchedSecurityDesc = res.data.resData;
                  res.data.resData.forEach((data) => {
                    this.optSecurityDescriptions.push(data.SecurityTicker);
                  });

                  this.loadingSecurityDesc = false;
                } else {
                  this.notFoundMessage("security desc.");
                  this.loadingSecurityDesc = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    selectedSecuritydesc(search) {
      this.tradeForm.isin = null;
      this.tradeForm.clientPriceType = null;
      this.tradeForm.exchangeTraded = null;

      let selecting = this.searchedSecurityDesc.find(
        (s) => s.SecurityTicker === search
      );
      this.tradeForm.isin = selecting.SecurityIsin;
      this.tradeForm.clientPriceType = selecting.SecurityCcy;
      this.tradeForm.quantity = selecting.DefaultShares
      this.refreshAmount(true)

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedSecuritydescForExchange`, {
            auth: this.user,
            search: search,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              // this.tradeForm.exchangeTraded = res.data.resData.Exchange;
       
              this.optExchangeTrades = [];
              if(res.data.resData.length>0){
                res.data.resData.forEach(element => {
                  if(element!=null){
                    this.optExchangeTrades.push(element)
                  }              });

              }

              this.checkCommission();
            }
          })
          .catch((error) => reject(error));
      });
    },

    searchClient(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingClient = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchClient`, { auth: this.user, search: search })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optClients = [];
                  this.searchedClients = res.data.resData;
                  res.data.resData.forEach((data) => {
                    this.optClients.push(data.AccountName);
                  });

                  this.loadingClient = false;
                } else {
                  this.notFoundMessage("Client");
                  this.loadingClient = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    selectedClient(search) {
      this.tradeForm.clientAccount = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.clientAccount = selecting.AccountNumber;
      this.checkCommission();
    },

    checkCommission() {
      console.log(this.tradeForm.clientAccount);
      console.log(this.tradeForm.securityDesc);
      console.log(this.tradeForm.cptyAccount);

      if (this.tradeForm.clientAccount && this.tradeForm.securityDesc) {
        let client = this.searchedClients.find(
          (s) => s.AccountNumber === this.tradeForm.clientAccount
        );

        let security = this.searchedSecurityDesc.find(
          (s) => s.SecurityTicker == this.tradeForm.securityDesc
        );

        let commissionRate = this.commissions.find(
          (s) => s.Client == client.AccountName && s.Country == security.Country
        );
        if (commissionRate) {
          this.tradeForm.calculateCommissionFromRate1 = true;
          this.tradeForm.clientRate = commissionRate.Rate * 10000;
        } else {
          this.tradeForm.clientRate = null;
        }
      } else {
        this.tradeForm.clientRate = null;
      }

      if (this.tradeForm.cptyAccount && this.tradeForm.securityDesc) {
        let client = this.searchedClients.find(
          (s) => s.AccountNumber === this.tradeForm.cptyAccount
        );

        let security = this.searchedSecurityDesc.find(
          (s) => s.SecurityTicker == this.tradeForm.securityDesc
        );

        let commissionRate = this.commissions.find(
          (s) => s.Client == client.AccountName && s.Country == security.Country
        );
        if (commissionRate) {
          this.tradeForm.calculateCommissionFromRate2 = true;
          this.tradeForm.counterpartyRate = commissionRate.Rate * 10000;
        } else {
          this.tradeForm.counterpartyRate = null;
        }
      } else {
        this.tradeForm.counterpartyRate = null;
      }
    },

    selectedClientFromTable(client) {
      this.tradeForm.clientAccount = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === client.AccountName
      );
      this.tradeForm.client = client.AccountName;
      this.tradeForm.clientAccount = client.AccountNumber;
      this.viewFilteredClientModal = false;
    },
    seeFilteredClientModal() {
      this.viewFilteredClientModal = true;
    },
    resetClientModal() {
      this.viewFilteredClientModal = false;
    },
    searchCounterparty(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingCounterparty = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchCounterparty`, { auth: this.user, search: search })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optCounterParties = [];
                  this.searchedCounterparties = [];

                  this.searchedCounterparties = res.data.resData;

                  if (res.data.resData.length > 0) {
                    res.data.resData.forEach((data) => {
                      this.optCounterParties.push(data.AccountName);
                    });
                  }

                  this.loadingCounterparty = false;
                } else {
                  this.notFoundMessage("Counterparty");
                  this.loadingCounterparty = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    selectedCounterparty(search) {
      this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;
      this.optCounterPartyTraders = [];

      let selecting = this.searchedCounterparties.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.cptyAccount = selecting.AccountNumber;
      this.checkCommission();
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: search,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
              let listTraders = [];
              res.data.resTrader.forEach((element) => {
                if (!listTraders.includes(element.Trader)) {
                  listTraders.push(element.Trader);
                }
              });
              this.optCounterPartyTraders = listTraders;
            }
          })
          .catch((error) => reject(error));
      });
    },
    selectedCounterpartyFromTable(cpty) {
      this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;

      this.tradeForm.counterparty = cpty.AccountName;
      this.tradeForm.cptyAccount = cpty.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: cpty.AccountName,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
            }
            this.viewFilteredCounterpartyModal = false;
          })
          .catch((error) => reject(error));
      });
    },
    seeFilteredCounterpartyModal() {
      this.viewFilteredCounterpartyModal = true;
    },
    resetCounterpartyModal() {
      this.viewFilteredCounterpartyModal = false;
    },
    checkEnableOCOInputs(input) {
      if (input == "takeProfitLimit") {
        if (this.tradeForm.takeProfitLimit > 0) {
          if (this.tradeForm.buySell == "Buy") {
            if (this.tradeForm.stopLossStop > 0) {
              if (
                this.tradeForm.takeProfitLimit < this.tradeForm.stopLossStop
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t("equity_bond.messages.take_profit_lower_stop_price")
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
            if (
              this.tradeForm.takeProfitLimit > 0 &&
              this.tradeForm.stopLossLimit > 0
            ) {
              if (
                this.tradeForm.takeProfitLimit < this.tradeForm.stopLossLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t("equity_bond.messages.take_profit_lower_limit_price")
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
          } else if (
            this.tradeForm.buySell == "Sell" ||
            this.tradeForm.buySell == "Sell Short"
          ) {
            if (this.tradeForm.stopLossStop > 0) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossStop
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t("equity_bond.messages.take_profit_greater_stop_price")
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
            if (
              this.tradeForm.takeProfitLimit > 0 &&
              this.tradeForm.stopLossLimit > 0
            ) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "equity_bond.messages.take_profit_greater_limit_price"
                  )
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
          }
        } else {
          this.greaterThanZeroMessage(
            this.$t("equity_bond.messages.greater_then_zero_take_profit")
          );
        }
      } else if (input == "stopLossStop") {
        if (this.tradeForm.stopLossStop > 0) {
          if (this.tradeForm.buySell == "Buy") {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.stopLossStop > this.tradeForm.takeProfitLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t("equity_bond.messages.stop_loss_greater_profit_price")
                );
                this.tradeForm.stopLossStop = null;
              }
            }
          } else if (
            this.tradeForm.buySell == "Sell" ||
            this.tradeForm.buySell == "Sell Short"
          ) {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossStop
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t("equity_bond.messages.stop_loss_lower_profit_price")
                );
                this.tradeForm.stopLossStop = null;
              }
            }
          }
        } else {
          this.greaterThanZeroMessage(
            this.$t("equity_bond.messages.greater_then_zero_stop_loss")
          );
        }
      } else if (input == "stopLossLimit") {
        if (this.tradeForm.stopLossLimit > 0) {
          if (this.tradeForm.buySell == "Buy") {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.stopLossLimit > this.tradeForm.takeProfitLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "equity_bond.messages.stop_loss_limit_greater_profit_price"
                  )
                );
                this.tradeForm.stopLossLimit = null;
              }
            }
          } else if (
            this.tradeForm.buySell == "Sell" ||
            this.tradeForm.buySell == "Sell Short"
          ) {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "equity_bond.messages.stop_loss_limit_lower_profit_price"
                  )
                );
                this.tradeForm.stopLossLimit = null;
              }
            }
          }
        } else {
          this.greaterThanZeroMessage(
            this.$t("equity_bond.messages.greater_then_zero_stop_loss_limit")
          );
        }
      }
    },

    convertNumberInputFormat() {
      if (this.tradeForm.amount != null) {
        this.tradeForm.amount = Number(this.tradeForm.amount);
      }

      if (this.tradeForm.clientCommission != null) {
        this.tradeForm.clientCommission = Number(
          this.tradeForm.clientCommission
        );
      }
      if (
        this.tradeForm.clientPrice != null &&
        this.tradeForm.clientPrice > 0
      ) {
        this.tradeForm.clientPrice = Number(this.tradeForm.clientPrice);
      }
      if (this.tradeForm.cptyCommission != null) {
        this.tradeForm.cptyCommission = Number(this.tradeForm.cptyCommission);
      }
      if (this.tradeForm.accrued != null) {
        this.tradeForm.accrued = Number(this.tradeForm.accrued);
      }

      if (this.tradeForm.counterpartyPrice != null) {
        this.tradeForm.counterpartyPrice = Number(
          this.tradeForm.counterpartyPrice
        );
      }
      if (this.tradeForm.counterpartyAmount != null) {
        this.tradeForm.counterpartyAmount = Number(
          this.tradeForm.counterpartyAmount
        );
      }
      if (this.tradeForm.clientAmount != null) {
        this.tradeForm.clientAmount = Number(this.tradeForm.clientAmount);
      }
      if (this.tradeForm.stopLossLimit != null) {
        this.tradeForm.stopLossLimit = Number(this.tradeForm.stopLossLimit);
      }
      if (this.tradeForm.stopLossStop != null) {
        this.tradeForm.stopLossStop = Number(this.tradeForm.stopLossStop);
      }
      if (this.tradeForm.takeProfitLimit != null) {
        this.tradeForm.takeProfitLimit = Number(this.tradeForm.takeProfitLimit);
      }
      if (this.tradeForm.stopPrice != null) {
        this.tradeForm.stopPrice = Number(this.tradeForm.stopPrice);
      }
    },
    arrangeInputFormat() {
      if (
        this.tradeForm.clientPrice != null &&
        this.tradeForm.clientPrice > 0
      ) {
        this.tradeForm.clientPrice = Number(this.tradeForm.clientPrice).toFixed(
          2
        );
      }

      // if (this.tradeForm.clientRate != null) {
      //   this.tradeForm.clientRate = this.formatPrice2(this.tradeForm.clientRate, 6)
      // }

      // if (this.tradeForm.counterpartyRate != null) {
      //   this.tradeForm.counterpartyRate = this.formatPrice2(this.tradeForm.counterpartyRate, 6)
      // }

      if (this.tradeForm.clientCommission != null) {
        this.tradeForm.clientCommission = Number(
          this.tradeForm.clientCommission
        ).toFixed(2);
      }

      if (this.tradeForm.cptyCommission != null) {
        this.tradeForm.cptyCommission = Number(
          this.tradeForm.cptyCommission
        ).toFixed(2);
      }

      if (this.tradeForm.amount != null) {
        this.tradeForm.amount = Number(this.tradeForm.amount).toFixed(2);
      }

      if (this.tradeForm.counterpartyPrice != null) {
        this.tradeForm.counterpartyPrice = Number(
          this.tradeForm.counterpartyPrice
        ).toFixed(2);
      }
      if (this.tradeForm.counterpartyAmount != null) {
        this.tradeForm.counterpartyAmount = Number(
          this.tradeForm.counterpartyAmount
        ).toFixed(2);
      }
      if (this.tradeForm.clientAmount != null) {
        this.tradeForm.clientAmount = Number(
          this.tradeForm.clientAmount
        ).toFixed(2);
      }
      if (this.tradeForm.stopLossLimit != null) {
        this.tradeForm.stopLossLimit = Number(
          this.tradeForm.stopLossLimit
        ).toFixed(2);
      }
      if (this.tradeForm.stopLossStop != null) {
        this.tradeForm.stopLossStop = Number(
          this.tradeForm.stopLossStop
        ).toFixed(2);
      }
      if (this.tradeForm.takeProfitLimit != null) {
        this.tradeForm.takeProfitLimit = Number(
          this.tradeForm.takeProfitLimit
        ).toFixed(2);
      }
      if (this.tradeForm.stopPrice != null) {
        this.tradeForm.stopPrice = Number(this.tradeForm.stopPrice).toFixed(2);
      }
      if (this.tradeForm.profit != null) {
        this.tradeForm.profit = Number(this.tradeForm.profit).toFixed(2);
      }
      if (this.tradeForm.accrued != null) {
        this.tradeForm.accrued = Number(this.tradeForm.accrued).toFixed(2);
      }
    },

    refreshAmount(val = false) {
      this.convertNumberInputFormat();
      this.calculateClientFaceValue();
      this.calculateClientComission();
      this.calculateCounterpartyComission();
      this.calculateClientAmount();
      this.calculateCounterpartyAmount();
      this.calculateTCProfit();
      this.arrangeInputFormat();
    },

    calculateClientFaceValue() {
      if (this.tradeForm.quantity > 0 && this.tradeForm.quantity != null) {
        if (this.tradeForm.quantity.toString().indexOf(".") > -1) {
          this.tradeForm.quantity = Math.round(this.tradeForm.quantity);
        }
      }

      if (this.tradeForm.quantity > 0 && this.tradeForm.clientPrice > 0) {
        this.convertNumberInputFormat();
        if (this.tradeForm.instrumentType == "FI") {
          let cal =
            (this.tradeForm.quantity * this.tradeForm.clientPrice) / 100;

          this.tradeForm.amount = cal;
          // this.tradeForm.clientAmount = cal;
          // this.tradeForm.counterpartyAmount = cal;
        }
      }
    },
    calculateAccrued() {
      this.convertNumberInputFormat();
      this.refreshAmount(false);
      this.arrangeInputFormat();
    },
    calculateCptyPrice() {
      this.convertNumberInputFormat();
      this.refreshAmount(false);
      this.arrangeInputFormat();
    },
    calculateClientRateToComission() {
      this.convertNumberInputFormat();
      this.refreshAmount(false);
      this.arrangeInputFormat();
    },
    calculateClientComission() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.clientRate > -1
      ) {
        this.convertNumberInputFormat();
        if (this.tradeForm.instrumentType == "FI") {
          let temp = this.tradeForm.amount;

          if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
            temp = Number(temp) + Number(this.tradeForm.accrued);
          }

          this.tradeForm.clientCommission = this.formatPrice2(
            (Number(temp) * Number(this.tradeForm.clientRate)) / 10000,
            2
          );
        }

        if (this.tradeForm.clientCommission > 0) {
          this.tradeForm.clientAmount = this.tradeForm.amount;
          this.tradeForm.clientAmount =
            this.tradeForm.clientAmount + this.tradeForm.clientCommission;

          this.tradeForm.profit = this.tradeForm.clientCommission;
        }
      }
      this.arrangeInputFormat();
    },
    calculateCounterpartyRateToComission() {
      this.convertNumberInputFormat();
      this.refreshAmount(false);
      this.arrangeInputFormat();
    },
    calculateCounterpartyComission() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.counterpartyRate > -1
      ) {
        this.convertNumberInputFormat();

        if (this.tradeForm.instrumentType == "FI") {
          let temp = 0;
          temp = this.calculateConterpartyFaceValue();

          if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
            temp = Number(temp) + Number(this.tradeForm.accrued);
          }

          this.tradeForm.cptyCommission =
            (Number(temp) * Number(this.tradeForm.counterpartyRate)) / 10000;
        }
        if (this.tradeForm.cptyCommission > 0) {
          this.tradeForm.counterpartyAmount = this.tradeForm.amount;
          this.tradeForm.counterpartyAmount =
            this.tradeForm.counterpartyAmount + this.tradeForm.cptyCommission;
          this.tradeForm.profit =
            this.tradeForm.clientCommission +
            this.tradeForm.cptyCommission * -1;
        }

        this.arrangeInputFormat();
      }
    },
    calculateClientRate() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.clientCommission > -1
      ) {
        this.convertNumberInputFormat();
        if (this.tradeForm.instrumentType == "FI") {
          let temp = this.tradeForm.amount;

          if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
            temp = Number(temp) + Number(this.tradeForm.accrued);
          }

          this.tradeForm.clientRate = this.formatPrice2(
            (this.tradeForm.clientCommission / temp) * 10000,
            6
          );
        }

        if (this.tradeForm.clientRate > -1) {
          this.tradeForm.clientAmount = this.tradeForm.amount;
          this.tradeForm.clientAmount =
            this.tradeForm.clientAmount + this.tradeForm.clientCommission;
          this.tradeForm.profit =
            this.tradeForm.clientCommission +
            this.tradeForm.cptyCommission * -1;
        }
        this.convertNumberInputFormat();
        this.calculateClientFaceValue();
        this.calculateClientAmount();
        this.calculateCounterpartyAmount();
        this.calculateTCProfit();
        this.arrangeInputFormat();
      }
    },
    calculateCounterpartyRate() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.cptyCommission > -1
      ) {
        this.convertNumberInputFormat();

        if (this.tradeForm.instrumentType == "FI") {
          let temp = 0;
          temp = this.calculateConterpartyFaceValue();

          if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
            temp = temp + this.tradeForm.accrued;
          }

          this.tradeForm.counterpartyRate = this.formatPrice2(
            (this.tradeForm.cptyCommission / temp) * 10000,
            6
          );
        }

        if (this.tradeForm.counterpartyRate > -1) {
          this.tradeForm.counterpartyAmount = this.tradeForm.amount;
          this.tradeForm.counterpartyAmount =
            Number(this.tradeForm.counterpartyAmount) +
            Number(this.tradeForm.cptyCommission);
          this.tradeForm.profit =
            Number(this.tradeForm.clientCommission) +
            Number(this.tradeForm.cptyCommission) * -1;
        }
        this.convertNumberInputFormat();
        this.calculateClientFaceValue();
        this.calculateClientAmount();
        this.calculateCounterpartyAmount();
        this.calculateTCProfit();
        this.arrangeInputFormat();
      }
    },
    calculateConterpartyFaceValue() {
      if (this.tradeForm.quantity > 0) {
        this.convertNumberInputFormat();

        if (
          this.tradeForm.counterpartyPrice != null &&
          this.tradeForm.instrumentType == "FI"
        ) {
          // this.tradeForm.amount =
          //   (Number(this.tradeForm.quantity) *
          //     Number(this.tradeForm.counterpartyPrice)) /
          //   100;

          //   console.log(this.tradeForm.amount )
          return (
            (Number(this.tradeForm.quantity) *
              Number(this.tradeForm.counterpartyPrice)) /
            100
          );
        } else {
          return 0;
        }
      }
    },
    calculateClientAmount() {
      let temp = 0;
      this.convertNumberInputFormat();
      if (this.tradeForm.amount > 0) {
        temp = Number(temp) + Number(this.tradeForm.amount);
      }

      if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
        if (this.tradeForm.instrumentType == "FI") {
          temp = Number(temp) + Number(this.tradeForm.accrued);
        }
      }

      if (this.tradeForm.clientCommission != null) {
        if (this.tradeForm.buySell == "Buy") {
          temp = Number(temp) + Number(this.tradeForm.clientCommission);
        } else {
          temp = Number(temp) - Number(this.tradeForm.clientCommission);
        }
      }

      if (temp > 0) {
        this.tradeForm.clientAmount = temp;
        //this.tradeForm.counterpartyAmount = temp;
        this.arrangeInputFormat();
      } else {
        this.tradeForm.clientAmount = null;
      }
    },
    calculateCounterpartyAmount() {
      let temp = 0;
      this.convertNumberInputFormat();
      temp = temp + this.calculateConterpartyFaceValue();

      if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
        if (this.tradeForm.instrumentType == "FI") {
          temp = Number(temp) + Number(this.tradeForm.accrued);
        }
      }

      if (this.tradeForm.cptyCommission != null) {
        if (this.tradeForm.buySell == "Buy") {
          temp = Number(temp) + Number(this.tradeForm.cptyCommission);
        } else {
          temp = Number(temp) - Number(this.tradeForm.cptyCommission);
        }
      }

      if (temp > 0) {
        //this.tradeForm.clientAmount = temp;
        this.tradeForm.counterpartyAmount = Number(temp);
        this.arrangeInputFormat();
      } else {
        this.tradeForm.counterpartyAmount = null;
      }
    },
    calculateTCProfit() {
      let temp = 0;
      if (
        this.tradeForm.clientAmount > 0 &&
        this.tradeForm.counterpartyAmount > 0
      ) {
        this.convertNumberInputFormat();
        if (this.tradeForm.buySell == "Buy") {
          this.tradeForm.profit = this.formatPrice2(
            this.tradeForm.clientAmount - this.tradeForm.counterpartyAmount,
            2
          );
        } else {
          this.tradeForm.profit = this.formatPrice2(
            -this.tradeForm.clientAmount + this.tradeForm.counterpartyAmount,
            2
          );
        }
        this.arrangeInputFormat();
      } else {
        this.tradeForm.profit = null;
      }
    },

    getAllSecurityDesc() {
      axiosIns
        .post(`getAllVerifiedFISecurities`)
        .then((res) => {
          if (res.data.length > 0) {
            this.optSecurityDescriptions = [];
            this.searchedSecurityDesc = [];
            this.searchedSecurityDesc = res.data;
            res.data.forEach((data) => {
              this.optSecurityDescriptions.push(data.SecurityTicker);
            });
          } else {
            this.notFoundMessage("security desc.");
          }
        })
        .catch((error) => console.log(error));
    },

    getHolidays() {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getHolidays`, this.user)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => reject(error));
      });
    },

    checkHolidaysDate(holidays, tradeDate) {
      let checkDate = tradeDate;
      let status = true;
      holidays.forEach((h) => {
        if (this.dateFormat(h.Date) == this.dateFormat(tradeDate)) {
          checkDate.setDate(checkDate.getDate() + 1);
          status = false;
          this.checkHolidaysDate(holidays, checkDate);
        }
      });
      if (status) {
        this.checkedHolidayDate = checkDate;
      }
    },

    arrangeTPlus() {
      if (
        this.tradeForm.tradeDate != null &&
        this.tradeForm.tradeDateName != null
      ) {
        if (this.tradeForm.Tplus > 0) {
          this.getHolidays().then((holidays) => {
            if (holidays.length > 0) {
              let tradeDate = new Date(
                this.MDYdateFormat(this.tradeForm.tradeDate)
              );

              let period = this.tradeForm.Tplus;
              tradeDate.setDate(tradeDate.getDate() + period);

              this.checkHolidaysDate(holidays, tradeDate);

              //found settlement date name
              let settlementDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];

              //check weekend settlement date
              if (settlementDateName == "Saturday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 2
                );
              } else if (settlementDateName == "Sunday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 1
                );
              }

              //again check holiday
              this.checkHolidaysDate(holidays, this.checkedHolidayDate);

              // set settlement date
              this.tradeForm.settlementDate = this.dateFormat(
                this.checkedHolidayDate
              );
              this.tradeForm.settlementDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];
            } else {
              let tradeDate = new Date(
                this.MDYdateFormat(this.tradeForm.tradeDate)
              );

              let period = this.tradeForm.Tplus;

              tradeDate.setDate(tradeDate.getDate() + period);

              //found settlement date name
              let settlementDateName = this.weekday[tradeDate.getDay()];

              //check weekend settlement date
              if (settlementDateName == "Saturday") {
                tradeDate.setDate(tradeDate.getDate() + 2);
              } else if (settlementDateName == "Sunday") {
                tradeDate.setDate(tradeDate.getDate() + 1);
              }

              // set settlement date
              this.tradeForm.settlementDate = this.dateFormat(tradeDate);
              this.tradeForm.settlementDateName = this.weekday[
                tradeDate.getDay()
              ];
            }
          });
        }
      }
    },
    checkTraSettlCptyDate(type, defDateName = false) {
      if (type == "Trade Date") {

        if(defDateName){
            this.defTradeDateName = null
          }

        let tradeDate = new Date(this.MDYdateFormat(this.tradeForm.tradeDate));

        //set trade date name
        this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];

        //arrange as T+
        if (this.tradeForm.Tplus > 0) {
          this.arrangeTPlus();
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.tradeDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.trade_date_before_order_date")
          );
          this.tradeForm.tradeDate = null;
        }
        if (this.tradeForm.instrumentType == "FI") {
          if (
            Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
            Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
          ) {
            this.checkedTraSettlDateMessage(
              this.$t(
                "equity_bond.messages.trade_date_before_counterparty_order_date"
              )
            );
            this.tradeForm.tradeDate = null;
          }
        }
      } else if (type == "Settlement Date") {
        let settleDate = new Date(
          this.MDYdateFormat(this.tradeForm.settlementDate)
        );

        //set settle date name
        this.tradeForm.settlementDateName = this.weekday[settleDate.getDay()];

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.settlementDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.settlementDate = null;
        }
        if (this.tradeForm.instrumentType == "FI") {
          if (
            Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
            Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
          ) {
            this.checkedTraSettlDateMessage(
              this.$t(
                "equity_bond.messages.settlement_date_before_counterparty_order_date"
              )
            );
            this.tradeForm.settlementDate = null;
          }
        }
      } else if ("Cpty Order Date") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "equity_bond.messages.trade_date_before_counterparty_order_date"
            )
          );
          this.tradeForm.cptyOrderDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "equity_bond.messages.settlement_date_before_counterparty_order_date"
            )
          );
          this.tradeForm.cptyOrderDate = null;
        }
      }
    },
    checkTime(type) {
      if (type == "Order Time") {

        if(this.tradeForm.orderTime != null && this.tradeForm.orderTime.length < 8){
    
        for(let i= this.tradeForm.orderTime.length; i < 8; i++){
          if(i == 2 ){
            this.tradeForm.orderTime += ":"
          } else if(i== 5){
            this.tradeForm.orderTime += ":"
          } else {
            this.tradeForm.orderTime += "0"
          }
        }
      }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t("equity_bond.messages.order_time_later_execution_time")
            );
            this.tradeForm.orderTime = null;
          }
        }

        this.checkOrdersTime("Order Time");
      } else if ("Execution Time") {

        if(this.tradeForm.executionTime != null && this.tradeForm.executionTime.length < 8){
    
        for(let i= this.tradeForm.executionTime.length; i < 8; i++){
          if(i == 2 ){
            this.tradeForm.executionTime += ":"
          } else if(i== 5){
            this.tradeForm.executionTime += ":"
          } else {
            this.tradeForm.executionTime += "0"
          }
        }
      }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t("equity_bond.messages.execution_time_later_order_time")
            );
            this.tradeForm.executionTime = null;
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          if (this.tradeForm.cptyOrderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t(
                "equity_bond.messages.execution_time_later_counterparty_order_time"
              )
            );
            this.tradeForm.executionTime = null;
          }
        }
      }
    },
    checkOrdersTime(type) {
      if (type == "Order Duration Time") {

        if(this.tradeForm.orderDurationTime != null && this.tradeForm.orderDurationTime.length < 8){
        
        for(let i= this.tradeForm.orderDurationTime.length; i < 8; i++){
          if(i == 2 ){
            this.tradeForm.orderDurationTime += ":"
          } else if(i== 5){
            this.tradeForm.orderDurationTime += ":"
          } else {
            this.tradeForm.orderDurationTime += "0"
          }
        }
      }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.orderDurationTime) {
            this.checkTimeMessage(
              this.$t("equity_bond.messages.order_duration_later_order_time")
            );
            this.tradeForm.orderDurationTime = null;
          }
        }
      } else if (type == "Order Time") {

        if(this.tradeForm.orderTime != null && this.tradeForm.orderTime.length < 8){
        
        for(let i= this.tradeForm.orderTime.length; i < 8; i++){
          if(i == 2 ){
            this.tradeForm.orderTime += ":"
          } else if(i== 5){
            this.tradeForm.orderTime += ":"
          } else {
            this.tradeForm.orderTime += "0"
          }
        }
      }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.orderDurationTime) {
            this.checkTimeMessage(
              this.$t(
                "equity_bond.messages.order_time_less_order_duration_time"
              )
            );
            this.tradeForm.orderTime = null;
          }
        }
      }
    },
    checkOrdersDate(type, defDateName = false) {
      if (type == "Order Date") {
        if(defDateName) {
        this.tradeForm.orderDateName = null
        }

        if (this.tradeForm.timeInForce == "DAY") {
          this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.order_date_after_order_duration_date")
          );
          this.tradeForm.orderDate = null;
        }
      } else if (type == "Order Duration Date") {
        if(defDateName) {
        this.tradeForm.orderDurationDateName = null
        }
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.order_duration_less_order_date")
          );
          this.tradeForm.orderDurationDate = null;
        }
      }
    },
    changeTimeInForceType() {
      if (this.tradeForm.timeInForce == "DAY") {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString();
        this.orderDurationDataDisabled = true;
        this.orderDurationTimeDisabled = true;
        this.orderDurationInputsVisible = true;
      } else if (this.tradeForm.timeInForce == "GTC") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      } else if (this.tradeForm.timeInForce == "GTD") {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString();
        this.orderDurationDataDisabled = false;
        this.orderDurationTimeDisabled = false;
        this.orderDurationInputsVisible = true;
      } else if (this.tradeForm.timeInForce == "FOK") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      } else if (this.tradeForm.timeInForce == "FAK") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      }
    },
    resetTradeModal() {
      this.enterNewTradeModal = false;
      this.fetchedTradeData = {};
      this.checkedHolidayDate = null;
      this.optSecurityDescriptions = [];
      // this.optClients = [];
      this.optClientPriceTypes = [];
      this.optExchangeTrades = [];
      // this.optCounterParties = [];
      this.optCounterPartyTraders = [];
      this.optScfUsers = [];
      this.optOrderTakenVias = [];
      this.optOrderGivenThroughs = [];
      this.searchedSecurityDesc = [];
      // this.searchedCounterparties = []
      // this.searchedClients = []
      this.showDefaultEntry = false;
      //
      this.tradeForm = {
        costCenter: null,

        orderDurationDate: moment().format("DD-MM-YYYY "),
        orderDurationDateName: null,
        orderDurationTime: moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString(),
        entryType: "Order",
        buySell: "Buy",
        orderType: "Limit",
        timeInForce: "DAY",
        orderDate: moment().format("DD-MM-YYYY"),
        orderDateName:null,
        orderTime: moment().format("HH:mm:ss"),
        cptyOrderDate: null,
        cptyOrderTime: null,
        enableConnectedOCOOrders: false,
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        instrumentType: "FI",
        prop: false,
        securityDesc: null,
        quantity: null,
        clientPrice: null,
        clientPriceType: null,
        amount: null,
        counterpartyPrice: null,
        clean: false,
        accrued: null,
        client: null,
        clientAccount: null,
        isin: null,
        exchangeTraded: null,
        counterparty: null,
        cptyAccount: null,
        counterpartyTrader: null,
        scfUser: null,
        actingCapaticy: "PRINCIPAL",
        tradeDate: null,
        tradeDateName: null,
        defTradeDateName:null,
        settlementDate: null,
        settlementDateName: null,
        Tplus: null,
        executionTime: null,
        cptyExecutionTime: null,
        clientRate: null,
        clientCommission: null,
        clientAmount: null,
        counterpartyRate: null,
        cptyCommission: null,
        counterpartyAmount: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        profit: null,
        tradeReportingNeeded: null,
        note: null,
        calculateCommissionFromRate1: true,
        calculateCommissionFromRate2: true,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,
      };
    },
    greaterThanZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `${val} must be greater than 0`,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedEnableOCOInputsMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("equity_bond.toast_messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedTraSettlDateMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTimeMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `The searched ${val} data is not found`,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    createTradeToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("equity_bond.toast_messages.trade_create_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    successMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: val,
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    saveDefaultEntrySuccessToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("general_title.save_default_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("equity_bond.general_messages.not_authorized_message"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    formatPrice2(value, exp) {
      if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

      value = +value;
      exp = +exp;

      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split("e");
      value = Math.round(
        +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
      );

      // Shift back
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
    },
    setTradeData() {
      if (
        this.tradeForm.timeInForce == "GTC" ||
        this.tradeForm.timeInForce == "FOK" ||
        this.tradeForm.timeInForce == "FAK"
      ) {
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      }

      let setTrade = {
        costCenter: this.tradeForm.costCenter,
        instrumentType: "FI",
        tradeDate:
          this.tradeForm.tradeDate != null
            ? this.YMDdateFormat(this.tradeForm.tradeDate)
            : null,
        settlementDate:
          this.tradeForm.settlementDate != null
            ? this.YMDdateFormat(this.tradeForm.settlementDate)
            : null,
        orderTime:
          this.tradeForm.orderTime != null
            ? this.timeFormat(this.tradeForm.orderTime)
            : null,
        executionTime:
          this.tradeForm.executionTime != null
            ? this.timeFormat(this.tradeForm.executionTime)
            : null,
        client: this.tradeForm.client,
        account: this.tradeForm.clientAccount,
        securityDesc: this.tradeForm.securityDesc,
        isin: this.tradeForm.isin,
        buySell: this.tradeForm.buySell,
        quantity: this.tradeForm.quantity != null ? this.tradeForm.quantity : 0,
        clientAmount:
          this.tradeForm.clientAmount != null
            ? parseFloat(this.tradeForm.clientAmount)
            : null,
        counterpartyRate:
          this.tradeForm.counterpartyRate != null
            ? parseFloat(this.tradeForm.counterpartyRate)
            : null,
        comissionRate:
          this.tradeForm.clientRate != null
            ? parseFloat(this.tradeForm.clientRate)
            : null,
        counterpartyComission:
          this.tradeForm.cptyCommission != null
            ? parseFloat(this.tradeForm.cptyCommission)
            : null,
        commissionAmount:
          this.tradeForm.clientCommission != null
            ? parseFloat(this.tradeForm.clientCommission)
            : null,
        counterpartyAmount:
          this.tradeForm.counterpartyAmount != null
            ? parseFloat(this.tradeForm.counterpartyAmount)
            : null,
        counterpartyAccount: this.tradeForm.cptyAccount,
        counterpartyTrader: this.tradeForm.counterpartyTrader,
        counterpartyPrice:
          this.tradeForm.counterpartyPrice != null
            ? parseFloat(this.tradeForm.counterpartyPrice)
            : null,
        clean: this.tradeForm.clean ? 1 : 0,
        accuredInterest:
          this.tradeForm.accrued != null
            ? parseFloat(this.tradeForm.accrued)
            : null,
        ccy: this.tradeForm.clientPriceType,
        price:
          this.tradeForm.clientPrice != null
            ? parseFloat(this.tradeForm.clientPrice)
            : null,
        amount:
          this.tradeForm.amount != null
            ? parseFloat(this.tradeForm.amount)
            : null,
        counterparty: this.tradeForm.counterparty,
        tcUser: this.tradeForm.scfUser,
        orderTakenVia: this.tradeForm.orderTakenVia,
        orderGivenThrough: this.tradeForm.orderGivenThrough,
        orderPrice: null,
        margin: this.tradeForm.tradeReportingNeeded ? 1 : 0,
        exchangeTraded: this.tradeForm.exchangeTraded,
        prop: this.tradeForm.prop ? 1 : 0,
        savedBy: this.user.name
          ? this.user.name + " " + this.user.surname
          : null,
        saveDate: moment().format("YYYY-MM-DD"),
        saveTime: moment().format("HH:mm:ss"),
        amendedBy: null,
        amendmentDate: null,
        amentmentTime: null,
        amendmentReason: null,
        canceled: 0,
        canceledBy: null,
        canceledDate: null,
        canceledTime: null,
        canceledReason: null,
        traderNote: this.tradeForm.note,
        securityAccount: null,
        actingCapaticy: this.tradeForm.actingCapaticy,
        counterpartOrderTime:
          this.tradeForm.cptyOrderTime != null
            ? this.timeFormat(this.tradeForm.cptyOrderTime)
            : null,
        counterpartyExecutionTime:
          this.tradeForm.cptyExecutionTime != null
            ? this.timeFormat(this.tradeForm.cptyExecutionTime)
            : null,
        tcUti: this.tradeForm.uti,
        uniqueLinkId: this.tradeForm.uniqueLinkID,
        tcProfit:
          this.tradeForm.profit != null
            ? parseFloat(this.tradeForm.profit)
            : null,
        entryType: this.tradeForm.entryType,
        orderType: this.tradeForm.orderType,
        timeInForce: this.tradeForm.timeInForce,
        stopPrice:
          this.tradeForm.stopPrice != null
            ? parseFloat(this.tradeForm.stopPrice)
            : null,
        trailingAmount:
          this.tradeForm.trailingAmount != null
            ? parseFloat(this.tradeForm.tradeForm)
            : null,
        takeProfit: null,
        stopLoss:
          this.tradeForm.stopLossStop != null
            ? parseFloat(this.tradeForm.stopLossStop)
            : null,
        orderDate:
          this.tradeForm.orderDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDate)
            : null,
        counterpartyOrderDate:
          this.tradeForm.cptyOrderDate != null
            ? this.YMDdateFormat(this.tradeForm.cptyOrderDate)
            : null,
        orderDurationDate:
          this.tradeForm.orderDurationDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDurationDate)
            : null,
        orderDurationTime:
          this.tradeForm.orderDurationTime != null
            ? this.timeFormat(this.tradeForm.orderDurationTime)
            : null,
        enableOCO:
          this.tradeForm.enableConnectedOCOOrders != null
            ? this.tradeForm.enableConnectedOCOOrders
            : false,
        ocoType: null,
        takeProfitLimit:
          this.tradeForm.takeProfitLimit != null
            ? parseFloat(this.tradeForm.takeProfitLimit)
            : null,
        stopLossLimit:
          this.tradeForm.stopLossLimit != null
            ? parseFloat(this.tradeForm.stopLossLimit)
            : null,
        ocoLink: null,
        orderQuantity: null,
        future4: null,
        future5: null,
        future6: null,
        future7: null,
        future8: null,
        future9: null,
        future10: null,
        future11: null,
        future12: null,
        future13: null,
        future14: null,
        future15: null,
        future16: null,
        // startTradeDate:
        //   this.tradeForm.tradeStart != null
        //     ? this.YMDdateFormat(this.tradeForm.tradeStart)
        //     : null,
        // endTradeDate:
        //   this.tradeForm.tradeEnd != null
        //     ? this.YMDdateFormat(this.tradeForm.tradeEnd)
        //     : null,
        // startSettlementDate:
        //   this.tradeForm.settleStart != null
        //     ? this.YMDdateFormat(this.tradeForm.settleStart)
        //     : null,
        // endSettlementDate:
        //   this.tradeForm.settleEnd != null
        //     ? this.YMDdateFormat(this.tradeForm.settleEnd)
        //     : null,
        // ID: 0,
      };

      return setTrade;
    },
  },
  watch: {
    // "tradeForm.orderDate": {
    //   handler: function(val, before) {
    //     console.log(val)
    //   },
    //   deep: true,
    // },
    showDefaultEntry: {
      handler: function(val, before) {
        if (!val) {
          this.defaultEntryName = null;
        }
      },
    },
    enterNewTradeModal: {
      handler: function(val, before) {
        if (val) {
          this.getArangeEquityBondTCUsers();
          //this.getArrangeEquityBondCurrencies();
          this.getArrangeEquityBondOrderTakenGivenThrough();

          this.tradeForm.scfUser = this.user.name + " " + this.user.surname;
          this.tradeForm.orderDurationDate = moment().format("DD-MM-YYYY ");
          this.tradeForm.orderDurationTime = moment()
            .endOf("day")
            .format("HH:mm:ss")
            .toString();

          this.tradeForm.entryType = "Order";
          this.tradeForm.buySell = "Buy";
          this.tradeForm.orderType = "Limit";
          this.tradeForm.timeInForce = "DAY";
          this.tradeForm.orderDate = moment().format("DD-MM-YYYY");
          this.tradeForm.orderTime = moment().format("HH:mm:ss");
          this.tradeForm.tradeDate = moment().format("DD-MM-YYYY");
          this.tradeForm.actingCapaticy = "PRINCIPAL";
          this.tradeForm.instrumentType = "FI";

          let tradeDate = new Date(this.MDYdateFormat(this.tradeForm.tradeDate));

            //set trade date name
            this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];
        }
      },
    },
    "tradeForm.buySell": {
      handler: function(val, before) {
        this.convertNumberInputFormat();
        this.calculateClientFaceValue();
        this.calculateClientComission();
        this.calculateCounterpartyComission();
        this.calculateClientAmount();
        this.calculateCounterpartyAmount();
        this.calculateTCProfit();
        this.checkEnableOCOInputs();
      },
      deep: true,
    },
    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {
          this.tradeForm = {
            costCenter: val.CostCenter,

            orderDurationDate:
              val.OrderDurationDate != null
                ? this.dateFormat(val.OrderDurationDate)
                : null,
            orderDurationTime:
              val.OrderDurationTime != null ? val.OrderDurationTime : null,
            entryType: val.EntryType ?? null,
            buySell: val.BuySell ?? null,
            orderType: val.OrderType ?? null,
            timeInForce: val.TimeinForce ?? null,
            orderDate:
              val.OrderDate != null ? this.dateFormat(val.OrderDate) : null,
            orderTime: val.OrderTime != null ? val.OrderTime : null,
            cptyOrderDate:
              val.CounterpartyOrderDate != null
                ? this.dateFormat(val.CounterpartyOrderDate)
                : null,
            cptyOrderTime: val.CptyOrderTime != null ? val.CptyOrderTime : null,
            enableConnectedOCOOrders: val.EnableOCO ? true : false,
            stopPrice: val.StopPrice ?? null,
            trailingAmount: val.TrailingAmount ?? null,
            takeProfitLimit: val.TakeProfitLimit ?? null,
            stopLossStop: val.StopLoss ?? null,
            stopLossLimit: val.StopLossLimit ?? null,
            instrumentType: "FI",
            securityDesc: val.SecurityDescription ?? null,
            quantity: val.Quantity ?? null,
            clientPrice:
              val.Price != null ? this.formatPrice(val.Price, 2) : null,
            clientPriceType: val.Ccy ?? null,
            amount: val.Amount != null ? this.formatPrice(val.Amount, 2) : null,
            counterpartyPrice:
              val.CounterpartyPrice != null
                ? this.formatPrice(val.CounterpartyPrice, 2)
                : null,
            clean: val.Clean == 0 ? false : true,
            accrued: val.AccuredInterest ?? null,
            client: val.Client ?? null,
            clientAccount: val.Account ?? null,
            isin: val.Isin ?? null,
            exchangeTraded: val.ExchangeTraded ?? null,
            counterparty: val.Counterparty ?? null,
            cptyAccount: val.CounterpartyAccount ?? null,
            counterpartyTrader: val.CounterpartyTrader ?? null,
            scfUser: val.TcUser ?? null,
            actingCapaticy: val.ActingCapacity ?? null,
            tradeDate:
              val.TradeDate != null ? this.dateFormat(val.TradeDate) : null,
            settlementDate:
              val.SettlementDate != null
                ? this.dateFormat(val.SettlementDate)
                : null,
            executionTime: val.ExecutionTime != null ? val.ExecutionTime : null,
            cptyExecutionTime:
              val.CptyExecutionTime != null ? val.CptyExecutionTime : null,
            clientRate:
              val.CommissionRate != null
                ? this.formatPrice(val.CommissionRate, 2)
                : null,
            clientCommission: val.CommissionAmount ?? null,
            clientAmount: val.ClientAmount ?? null,
            counterpartyRate: val.CounterpartyRate ?? null,
            cptyCommission: val.CounterpartyCommission ?? null,
            counterpartyAmount: val.CounterpartyAmount ?? null,
            orderTakenVia: val.OrderTakenVia ?? null,
            orderGivenThrough: val.OrderGivenThrough ?? null,
            profit: val.TcProfit ?? null,
            tradeReportingNeeded: val.tradeReportingNeeded == 0 ? false : true,
            note: val.TraderNote ?? null,
            calculateCommissionFromRate1: false,
            calculateCommissionFromRate2: false,
            uti: val.TcUti ?? null,
            uniqueLinkID: val.UniqueLinkId ?? null,
            tradeRef: val.id,
            tradeStart: null,
            tradeEnd: null,
            settleStart: null,
            settleEnd: null,
          };
        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.eq_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #191970;
}

.fi_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #cd853f;
}

.settlementDateName .input-group-append .input-group-text {
  color: red;
}

.tradeDateName .input-group-append .input-group-text {
  color: red;
}

.animate__animated {
  animation-fill-mode: none;
}

.input-dropdown .btn {
  padding: 0;
}

.shortcut-buttons-flatpickr-button{
  border-radius: 5px;
  background: white;
  border: 1px solid #d8d6de;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
